import React, { useState } from 'react'
import GroupTag from '../Group/GroupTag'
import { Card, Tooltip } from 'antd'
import { LocalizedString } from '@maphubs/mhtypes'
import { CSSProperties } from 'react'

import UnlockOutlined from '@ant-design/icons/UnlockOutlined'
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined'
import TeamOutlined from '@ant-design/icons/TeamOutlined'
import FileOutlined from '@ant-design/icons/FileOutlined'

export type CardConfig = {
  id: string
  title?: LocalizedString
  description?: LocalizedString
  image_url?: string
  link: string
  group: {
    group_id: string
  }
  data: Record<string, unknown>
  type: string
  isPublic?: boolean
  onClick: (link: string, data: Record<string, unknown>) => void
}
type Props = {
  t: (val: string | LocalizedString) => string
} & CardConfig
type State = {
  imageFailed?: boolean
}
const MapHubsCard = ({
  onClick,
  data,
  link,
  group,
  type,
  image_url,
  id,
  title,
  isPublic,
  t
}: Props): JSX.Element => {
  const [imageFailed, setImageFailed] = useState(false)

  let icon = <></>
  let toolTipText = ''
  const iconStyle: CSSProperties = {
    position: 'absolute',
    bottom: '6px',
    right: '6px'
  }

  if (type) {
    switch (type) {
      case 'layer': {
        icon = <FileOutlined style={iconStyle} />
        toolTipText = t('Layer')

        break
      }
      case 'group': {
        icon = <TeamOutlined style={iconStyle} />
        toolTipText = t('Group')

        break
      }
      case 'map': {
        icon = <EnvironmentOutlined style={iconStyle} />
        toolTipText = t('Map')

        break
      }
      // No default
    }
  }

  let image

  if (type === 'group' && (!image_url || imageFailed)) {
    image = (
      <div
        className='card-image valign-wrapper'
        style={{
          width: '200px',
          height: '150px'
        }}
      >
        <TeamOutlined
          className='omh-accent-text valign center-align'
          style={{
            fontSize: '80px',
            margin: 'auto'
          }}
        />
      </div>
    )
  } else if (type === 'layer' && (!image_url || imageFailed)) {
    image = (
      <div
        className='card-image valign-wrapper'
        style={{
          width: '200px',
          height: '150px'
        }}
      >
        <FileOutlined
          className='omh-accent-text valign center-align'
          style={{
            fontSize: '80px',
            margin: 'auto'
          }}
        />
      </div>
    )
  } else if (type === 'map' && (!image_url || imageFailed)) {
    image = (
      <div
        className='card-image valign-wrapper'
        style={{
          width: '200px',
          height: '150px'
        }}
      >
        <EnvironmentOutlined
          className='omh-accent-text valign center-align'
          style={{
            fontSize: '80px',
            margin: 'auto'
          }}
        />
      </div>
    )
  } else {
    image = (
      <div className='card-image'>
        <img
          width='200'
          height='150'
          src={image_url}
          onError={() => {
            setImageFailed(true)
          }}
          style={{ borderRadius: '6px' }}
          loading='lazy'
        />
      </div>
    )
  }

  return (
    <Card
      hoverable
      style={{
        width: 200,
        height: 250
      }}
      onClick={() => {
        if (onClick) {
          onClick(link, data)
        }
      }}
      styles={{
        body: {
          height: '100%',
          padding: '0'
        }
      }}
      id={id}
    >
      <style jsx global>
        {`
          .card-image {
            display: flex;
          }
        `}
      </style>
      {image}
      <div
        className='card-content word-wrap'
        style={{
          height: '150x',
          padding: '5px'
        }}
      >
        <b>{t(title)}</b> <br />
        {group && (
          <div
            className='valign-wrapper'
            style={{
              position: 'absolute',
              bottom: 5,
              left: 5
            }}
          >
            <GroupTag group={group.group_id} />
          </div>
        )}
        {isPublic && (
          <div
            style={{
              position: 'absolute',
              bottom: '2px',
              right: '30px'
            }}
          >
            <Tooltip title={t('Public Sharing Enabled')} placement='top'>
              <UnlockOutlined
                style={{
                  color: 'green'
                }}
              />
            </Tooltip>
          </div>
        )}
        <Tooltip title={toolTipText} placement='top'>
          {icon}
        </Tooltip>
      </div>
    </Card>
  )
}
export default MapHubsCard
