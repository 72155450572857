import { getToken, GetTokenParams } from 'next-auth/jwt'

const secret = process.env.NEXTAUTH_SECRET

const getAPIUser = async (req: GetTokenParams['req']) => {
  if (!secret) throw new Error('NEXTAUTH_SECRET env var is not set')
  return getToken({
    req,
    secret
  })
}

const getAPIUserNextSSR = async (headers: Headers) => {
  // recreate a req object from the headers
  const headersInst = new Headers(headers)
  const cookie = headersInst.get('cookie') || ''

  //convert cookie to object
  const cookieObj = cookie.split(';').reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    acc[key.trim()] = value
    return acc
  }, {})
  const req = {
    headers: headersInst,
    cookies: cookieObj
  }

  if (!secret) throw new Error('NEXTAUTH_SECRET env var is not set')
  return getToken({
    req,
    secret
  })
}

export { getAPIUser, getAPIUserNextSSR }
