import React, { useState } from 'react'
import { LocalizedInput } from '@maphubs/forms'
import { Modal, Row, Button, App, Form } from 'antd'
import { SelectGroup } from '@maphubs/ui'
import useMapT from '../hooks/useMapT'

import { LocalizedString, Group } from '@maphubs/mhtypes'

type Props = {
  onSave: (
    { title, group }: { title: LocalizedString; group: string },
    cb: () => void
  ) => void
  editing?: boolean
  editingLayer?: boolean
  owned_by_group_id: string
  initialTitle?: LocalizedString
  userGroups: Group[]
  loggedIn?: boolean
}

const SaveMapModal = ({
  owned_by_group_id,
  editing,
  editingLayer,
  initialTitle,
  onSave,
  userGroups,
  loggedIn
}: Props): JSX.Element => {
  const { message } = App.useApp()
  const { t } = useMapT()
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState(initialTitle)
  const [group, setGroup] = useState(owned_by_group_id)

  const recheckLogin = (): void => {
    // TODO: trigger next-auth to recheck login
    /*
      if (err) {
        message.error(t('Not Logged In - Please Login Again'))
      }
    */
  }
  const save = (): void => {
    if (!title || t(title) === '') {
      message.error(t('Please Add a Title'))
      return
    }
    let selectedGroup = group
    if (!group && userGroups.length === 1) {
      // creating a new layer when user is only the member of a single group (not showing the group dropdown)
      selectedGroup = userGroups[0].group_id
    }

    const closeSavingMessage = message.loading(t('Saving'), 0)
    onSave(
      {
        title,
        group: selectedGroup
      },
      () => {
        closeSavingMessage()
      }
    )
  }

  return (
    <>
      {!open && (
        <Button
          type='primary'
          disabled={editingLayer}
          onClick={() => {
            setOpen(true)
          }}
        >
          {t('Save Map')}
        </Button>
      )}
      <Modal
        title={t('Save Map')}
        open={open}
        onOk={save}
        styles={{
          body: {
            padding: '10px'
          }
        }}
        centered
        footer={[
          <Button
            key='back'
            onClick={() => {
              setOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>,
          <Button key='submit' type='primary' disabled={!title} onClick={save}>
            {t('Save Map')}
          </Button>
        ]}
        onCancel={() => {
          setOpen(false)
        }}
      >
        {loggedIn && (
          <Form
            layout='vertical'
            initialValues={{
              title: initialTitle,
              group: owned_by_group_id
            }}
            onValuesChange={(values) => {
              if (values.title) setTitle(values.title)
              if (values.group) setGroup(values.group)
            }}
          >
            <Form.Item name='title' label={t('Title')}>
              <LocalizedInput placeholder={t('Title')} onChange={setTitle} />
            </Form.Item>
            <Form.Item
              name='group'
              label={t('Group')}
              rules={[{ required: true }]}
              style={{ width: '100%' }}
            >
              <SelectGroup
                groups={userGroups}
                canChangeGroup={!editing}
                editing={editing}
                defaultValue={owned_by_group_id}
                t={t}
              />
            </Form.Item>
          </Form>
        )}
        {!loggedIn && (
          <>
            <Row
              style={{
                textAlign: 'center'
              }}
            >
              <p>{t('You must login or sign up before saving a map.')}</p>
            </Row>
            <Row
              style={{
                textAlign: 'center'
              }}
            >
              <Button
                type='primary'
                href='/login'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('Login')}
              </Button>
            </Row>
            <Row
              style={{
                textAlign: 'center'
              }}
            >
              <Button type='primary' onClick={recheckLogin}>
                {t('Retry')}
              </Button>
            </Row>
          </>
        )}
      </Modal>
    </>
  )
}
export default SaveMapModal
