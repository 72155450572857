import React, { useEffect, useState } from 'react'
import { Card, Segmented } from 'antd'
import Attributes from './Attributes'
import type { Layer, LocalizedString } from '@maphubs/mhtypes'
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined'
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import FeatureLocation from './FeaturePopup/FeatureLocation'
import QrcodeOutlined from '@ant-design/icons/QrcodeOutlined'
import PieChartOutlined from '@ant-design/icons/PieChartOutlined'
import FeatureQRCode from './FeaturePopup/FeatureQRCode'
import FeatureExport from './FeaturePopup/FeatureExport'
import FeatureArea from './FeaturePopup/FeatureArea'
import { Feature, MultiLineString, MultiPolygon, Point, Polygon } from 'geojson'
import type mapboxgl from 'mapbox-gl'

type SelectedFeature = Feature<
  Polygon | MultiPolygon | MultiLineString | Point
> & { layer: mapboxgl.Layer }

type Props = {
  features: SelectedFeature[]
  t: (val: string | LocalizedString) => string
  locale: string
}

const FeaturePopup = ({ features, t, locale }: Props): JSX.Element => {
  const [layerLoaded, setLayerLoaded] = useState(false)
  const [layer, setLayer] = useState<Layer>(null)

  const [selectedTab, setSelectedTab] = useState('attributes')

  useEffect(() => {
    if (features) {
      const selectedFeature = features[0]
      const properties = selectedFeature.properties

      if (properties.layer_id) {
        getLayer(properties.layer_id, properties.maphubs_host)
      } else {
        setLayerLoaded(true)
      }
    }
  }, [features])

  const getLayer = async (layerId: number, host: string) => {
    console.info(`Getting layer info for: ${layerId} from ${host}`)

    // this is to support remote layers on public sites like MapForEnvironment
    const baseUrl =
      host && host !== window.location.hostname ? 'https://' + host : ''

    if (window.location.pathname.startsWith(`/map/share/`)) {
      console.log(`layer lookup not supported on ${window.location.href}`)
      setLayerLoaded(true)
      return
    }
    try {
      const res = await fetch(baseUrl + '/api/layer/info/' + layerId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((res) => {
        if (res.status === 200) {
          return res.json() as Promise<{ layer?: Layer }>
        } else {
          throw new Error(res.statusText)
        }
      })
      if (res.layer) {
        setLayer(res.layer)
        setLayerLoaded(true)
      }
    } catch {
      setLayerLoaded(true)
      console.error(`failed to load layer info for: ${layerId}`)
    }
  }

  const renderFeature = (feature: SelectedFeature, i: number): JSX.Element => {
    if (!feature || !feature.properties) {
      return <p key={`popup-feature-${i}`}>{t('Error Invalid Feature')}</p>
    }

    return (
      <Card
        key='feature'
        styles={{
          body: {
            height: '100%',
            padding: '0'
          }
        }}
        style={{
          width: '196px',
          height: '200px',
          margin: 0,
          boxShadow: 'none'
        }}
      >
        <style jsx global>{`
          .feature-popup-tabs .anticon {
            margin: 0 !important;
          }
          .feature-popup-tabs .ant-tabs-tab {
            padding: 3px 6px !important;
          }
        `}</style>
        <div className='feature-popup-tabs'>
          <Segmented
            onChange={(key) => {
              setSelectedTab(key)
            }}
            value={selectedTab}
            options={[
              {
                value: 'attributes',
                icon: <InfoCircleOutlined style={{ fontSize: '16px' }} />
              },
              {
                value: 'location',
                icon: <EnvironmentOutlined style={{ fontSize: '16px' }} />
              },
              {
                value: 'area',
                icon: <PieChartOutlined style={{ fontSize: '16px' }} />
              },
              {
                value: 'qr',
                icon: <QrcodeOutlined style={{ fontSize: '16px' }} />
              },
              {
                value: 'export',
                icon: <DownloadOutlined style={{ fontSize: '16px' }} />
              }
            ]}
          />
          <div style={{ overflowY: 'auto', height: '160px' }}>
            {selectedTab === 'attributes' && (
              <Attributes attributes={feature.properties} t={t} />
            )}
            {selectedTab === 'location' && (
              <FeatureLocation geojson={feature} t={t} locale={locale} />
            )}
            {selectedTab === 'area' && (
              <FeatureArea geojson={feature} t={t} locale={locale} />
            )}
            {selectedTab === 'qr' && (
              <FeatureQRCode geojson={feature} t={t} locale={locale} />
            )}
            {selectedTab === 'export' && (
              <FeatureExport geojson={feature} t={t} />
            )}
          </div>
        </div>
      </Card>
    )
  }
  return <div>{features.map((feature, i) => renderFeature(feature, i))}</div>
}
FeaturePopup.defaultProps = {
  showButtons: true
}
export default FeaturePopup
